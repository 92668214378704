.block{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, .1);

  &.withSuperBg{
    background-color: transparent;
    &:after{
      content:"";
      position:absolute;
      z-index:-1;
      top:70px;
      bottom:70px;
      left: 70px;
      right: 70px;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 39px 70px rgba(255, 255, 255, 0.4);
      //background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 1) 28%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .image{
    height: 20%;
    max-height: 70px;
    max-width: 70px;
    object-fit: contain;
  }
}

