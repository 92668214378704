@mixin flex-spacing($width, $height: 0px) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  --flex-spacing-W: #{$width};
  --flex-spacing-H: #{$height};
  margin: {
    top: calc(var(--flex-spacing-H) / 2 * -1);
    bottom: calc(var(--flex-spacing-H) / 2 * -1);
    right: calc(var(--flex-spacing-W) / 2 * -1);
    left: calc(var(--flex-spacing-W) / 2 * -1);
  };
  >* {
    padding: {
      top: calc(var(--flex-spacing-H) / 2);
      bottom: calc(var(--flex-spacing-H) / 2);
      right: calc(var(--flex-spacing-W) / 2);
      left: calc(var(--flex-spacing-W) / 2);
    };
  }
}
