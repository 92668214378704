.MuiAccordion-root {
  box-shadow: none;
  margin: 0;
  border: {
    color: #E3EAF0;
    style: solid;
    width: 1px 0 1px 0;
    radius: unset;
  }
  padding: 0;
  & + .MuiAccordion-root {
    border-top-width: 0;
  }
  &:before {
    content: unset;
  }
  .MuiAccordionSummary-root {
    &.Mui-expanded{
      min-height: unset;
      .MuiIconButton-root .MuiIconButton-label {
        color: #BECBC8;
      }
    }
    padding: .5em 0;
    .MuiAccordionSummary-content {
      .MuiTypography-root{
        font-weight: 500;
      }
    }
    .MuiIconButton-root .MuiIconButton-label {
      font-size: 1.4em;
      color: var(--c-secondary);
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 0 1em;
    display: block;
  }
  .MuiIconButton-edgeEnd {
    margin-right: unset;
  }
}
