// here defined all layout sizes and positions
.layout{
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.separate{
    .main{
      padding-top: var(--header-h);
    }
  }
  .topDesktop,
  .topMobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  --header-h: 5em;

  .topDesktop {
    height: var(--header-h);
  }
  .topMobile {
    height: var(--header-h);
  }

  .main {
    //background-color: #F5F7F9;
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex-basis: auto;
    }
    .mainInner {
      flex: 1 0 auto;
      padding-top: var(--bls-mainContainer-pt);
      padding-bottom: var(--bls-mainContainer-pb);
    }
    .footer{
      flex-shrink: 0;
    }
  }
}
