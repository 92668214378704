.block {
  --c: #000000;
  --w: 26px;
  --h: 16px;

  --item-w: var(--w);
  --item-h: 2px;
  --item-br: 0;

  &.open .inner {
    transform: translateX(-50px);
    background-color: transparent;
    &::before {
      transform: rotate(45deg) translate(35px, -35px);
    }
    &::after {
      transform: rotate(-45deg) translate(35px, 35px);
    }
  }

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--w);
  height: var(--h);
  cursor: pointer;
  transition: background-color .1s;
  .inner {
    width: var(--item-w);
    height: var(--item-h);
    background-color: var(--c);
    border-radius: var(--item-br);
    will-change: backround-color;
    transition: background-color .1s, transform .5s;
    &::before,
    &::after {
      width: var(--item-w);
      height: var(--item-h);
      background-color: var(--c);
      border-radius: var(--item-br);
      transition: background-color .1s, transform .5s;
      content: '';
      position: absolute;
    }
    $shift: "var(--h)/2 - var(--item-h)/2";
    &::before {
      transform: translateY(calc(#{$shift}));
    }
    &::after {
      transform: translateY(calc((#{$shift}) * -1));
    }
  }
}
