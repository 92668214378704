.aside {
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  padding: 25px 25px;
}
.asideHeading {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 15px;
}

.pageHeading {
  margin-bottom: 30px;
}