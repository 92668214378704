@import "../../../assets/styles/mixins/flexSpacing";

.block {
  padding: 0 15px;
  color: inherit;
  background-color: white;
  border: {
    width: 0 0 1px;
    style: solid;
    color: #D9DBDF;
  };
  transition: background-color .1s, border-color .1s;

  &.transparent {
    background-color: transparent;
    border-color: transparent;
    color: white;
  }

  &.separate {
    background-color: white;
    border-color: #D9DBDF;
    color: inherit;
  }

  .inner {
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 100%;
    .left {
      height: 100%;
      justify-self: start;
      @include flex-spacing(20px);
    }
    .center {
      height: 100%;
      justify-self: center;
      @include flex-spacing(10px);
    }
    .right {
      height: 100%;
      justify-self: end;
      @include flex-spacing(20px);
    }
  }
}