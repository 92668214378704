.block{
  --p-tb: 1em;
  --p-lr: 1em;
  height: 100%;
  padding: var(--p-tb) var(--p-lr);
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

  &:hover,
  &:focus,
  &:active{
    &.isSticky .title {
      text-decoration: underline;
    }
    .title {
      color: #2F5AA8;
    }
  }

  &.isSticky {
    color: white;
    --p-tb: 1.1em;
    --p-lr: 1.1em;

    position: relative;
    .imgWrap {
      position: absolute;
      top: var(--p-tb);
      left: var(--p-lr);
      right: var(--p-tb);
      bottom: var(--p-lr);
      min-height: unset;
      width: calc(100% - var(--p-lr) * 2);
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content{
      position: relative;
      z-index: 1;
      margin-top: auto;
      padding: 15em 1em 1em;
      background: linear-gradient(0deg, #244456 -29.39%, rgba(0, 0, 0, 0) 100%);
      border-radius: 0 0 10px 10px;
      .title {
        transition: color .3s;
        color: inherit;
      }
      .date {
        color: inherit;
      }
    }
  }

  .imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;
    img {
      border-radius: 10px;

    }
  }
  .content{
    width: 100%;
    .title {
      transition: color .3s;
    }
    .description {

    }
    .date {
      color: #939393;
    }
  }
}

.postList {

}
