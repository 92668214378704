.MuiTabs-root{
  .MuiTabs-indicator{
    display: none;
  }
  .MuiTab-root {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: unset;
    &.Mui-selected{
      background-color: white;
    }
  }
}