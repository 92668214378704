.card {
  text-decoration: none;
  border-radius: 10px;
  padding: 2em 2.1em;
  background-color: #EAEFF4;
  transition: background-color .3s;

  display: flex;
  align-items: center;
  &:active,
  &:focus,
  &:hover {
    background-color: #e7e9ee;
  }
  //&.default {
  //
  //}
  &.primary {
    display: block;
    align-items: unset;
    .imgWrap{
      display: inline-block;
      background: #FFFFFF;
      border-radius: 0 0 10px 10px;
      padding: 1em;
      margin-top: -2em;
      margin-bottom: 1em;
    }
  }

  .imgWrap{
    img{

    }
  }
  .content {
    margin-left: 30px;
    h5{
      font-size: 1.2em;
      font-weight: normal;
      display: block;
      margin-bottom: 0;
    }
    i{
      color: #9DA1A8;
      font-style: normal;
      font-size: .9em;
    }
  }
}
