// https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;
  z-index: 1;

  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background-color: #F5F7F9;

  display: flex !important;
  align-items: center;
  justify-content: center;

  :hover,
  :focus{
    background-color: #E8EDF2;
  }
  &:before {
    content: "";
    height: 10px;
    width: 10px;
    display: block;
    border: {
      width: 2px 0 0 2px;
      style: solid;
      color: black;
    };
  }
  &.slick-disabled{
    opacity: .5;
    cursor: not-allowed;
  }
}
.slick-prev{
  left: 1em;
  &:before{
    transform: rotate(-45deg);
  }
}
.slick-next{
  right: 1em;
  &:before{
    transform: rotate(135deg);
  }
}


/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  padding: 0;

  display: block;

  width: 11px;
  height: 11px;

  cursor: pointer;

  color: transparent;
  border: {
    width: 2px;
    style: solid;
    color: #BABABA;
    radius: 10px;
  };
  outline: none;
  background: transparent;

  transition: background-color .3s, border-color .3s;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li.slick-active button {
  border-color: #3A4F5B;
  background-color: #3A4F5B;
}