.block{
  --p-tb: 1.4em;
  --p-lr: 1.4em;
  height: 100%;
  padding: var(--p-tb) var(--p-lr);
  border-radius: 10px;
  overflow: hidden;
  background: {
    color: white;
    position: right top;
    repeat: no-repeat;
    image: url("../../assets/images/bg-hexagones.svg");
  };
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.05));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active{
    .title {
      color: #2F5AA8;
    }
  }

  &.bordered {
    box-shadow: none;
    border: 1px solid #E3EAF0;
  }

  .imgWrap {
    align-self: flex-end;
    width: fit-content;
    margin: calc(var(--p-tb) * -1) calc(var(--p-lr) * -1) 1em calc(var(--p-lr) * -1);
    display: flex;
    align-items: center;
    justify-content: center;

    img {

    }
  }
  .title {
    margin-top: auto;
    transition: color .3s;
    margin-bottom: .2em;
  }
  .description {

  }
}
