@import "../../assets/styles/mixins/respondTo";

.slidesContainer {
  margin-left: -1em;
  margin-right: -1em;
  .slide {
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1em;
  }

  :global {
    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
      @include respond-to("md") {
        .slick-dots {
          bottom: unset;
          top: -50px;
          right: 1em;
          width: fit-content;
        }
      }
    }
  }
}

.star {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: .8em;
  background-color: var(--c-secondary);
  color: var(--c-secondary_contrast);
  padding: .7em;
  border-radius: 10px;
  margin-right: 1em;
}
