.block {
  padding: 5em 2em 4em;
  border: 1px solid rgba(var(--c-primary_rgb), .3);
  border-radius: 10px;
  text-align: center;
  position: relative;
  .icon {
    color: rgba(var(--c-primary_rgb), .6);
    margin: 0 auto 1.1em;
    font-size: 4em;
    line-height: 1;
  }
  .button {
    color: rgba(var(--c-primary_rgb), 1);
    background-color: rgba(var(--c-primary_rgb), .1);
    padding: 15px 25px;
    border-radius: 5px;
    border: none;
  }
  .message {
    position: absolute;
    top: .3em;
    left: .3em;
    right: .3em;
    bottom: .3em;
    color: white;
    font-size: 1.6em;
    padding: 20px 40px;
    background-color: rgba(var(--c-primary_rgb), .4);
    box-shadow: 0 0 5px rgba(var(--c-primary_rgb), .4);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
