@import "../../../assets/styles/mixins/flexSpacing";

.block {
  padding: 5px;
  color: inherit;
  background-color: white;
  border: {
    width: 0 0 1px;
    style: solid;
    color: #D9DBDF;
  };
  transition: background-color .1s, border-color .1s;
  &.transparent {
    background-color: transparent;
    border-color: transparent;
    color: white;
  }

  .topline{
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 100px 1fr;
    grid-template-rows: 100%;
    .left {
      height: 100%;
      justify-self: start;
      @include flex-spacing(10px);
    }
    .center {
      height: 100%;
      justify-self: center;
      @include flex-spacing(10px);
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
    .right {
      height: 100%;
      justify-self: end;
      @include flex-spacing(10px);
    }
  }

  .popup {
    --w: 75vw;
    width: var(--w);
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: calc(var(--w) * -1);
    transition: {
      duration: .3s;
      timing-function: ease-in-out;
      property: left, opacity, visibility;
    };

    background-color: white;
    opacity: 0;
    visibility: hidden;

    will-change: left, opacity, visibility;
    &.open {
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    color: var(--bls-text-c);

    padding:
            55px
            calc(1.2em + 15px)
            55px
            calc(1.2em + 15px);

    .popupInner{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
      grid-gap: 30px 0;
    }
  }
  .overlay {
    z-index: 4;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: {
      duration: .8s;
      timing-function: ease-in-out;
      property: opacity, visibility;
    };
    background-color: rgba(0,0,0,.5);

    opacity: 0;
    visibility: hidden;

    will-change: opacity, visibility;
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
}
