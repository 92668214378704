.block {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  width: 100%;

  .formWrap {
    align-self: center;
  }

  .option {

    a{

    }
  }
}