@import "../../assets/styles/mixins/respondTo";

html body .block:global(.MuiAccordion-root) {
  margin-bottom: 2em;
  padding: 0 2em;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  :global(.MuiAccordionSummary-expandIcon) {
    background-color: #F5F7F9;
    padding: .5em;
    border-radius: 5px;
  }
}

.imgWrap {
  text-align: center;
  border: {
    style: solid;
    width: 1px;
    color: #D3DBE3;
    radius: 10px;
  }
  display: block;
  img {
    height: 150px;
    border-radius: 10px;
  }

  transition: box-shadow .3s, border-color .3s;
  &:hover {
    border-color: #2F5AA8;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.08);
  }
}
.productName {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: #2F5AA8;
  }
}

.tabPanel{
  margin-top: -.6em;
  background-color: white;
  overflow: hidden;
  position: relative;
  border: {
    radius: 10px;
    style: solid;
    color: #E3EAF0;
    width: 1px;
  }
  padding: 2em;
  :global(.MuiTypography-h3) {
    margin-bottom: 1em;
    margin-top: -.3em;

  }
}

.product {
  margin-left: -2em;
  margin-right: -2em;
  padding: 1em 2em;
  border: {
    width: 1px 0 0 0;
    style: solid;
    color: #E3EAF0;
  }
  &.product_variantForSoftwareOnly {
    padding-left: 0;
    &:first-of-type {
      border-top-width: 0;
      margin-top: -2em;
    }
    display: flex;
    .toggleReadBtn{
      background: rgba(227, 234, 240, 0.5);
      border-radius: 0;
      margin-top: -1em;
      margin-bottom: -1em;
      margin-right: 1.6em;
      padding-left: 1em;
      padding-right: 1em;
      min-width: unset;
      font-size: 17px;
    }
  }
  &.product_hidden {
    background: rgba(227, 234, 240, 0.5);
    * {
      opacity: .7;
    }
  }
  &:last-of-type {
    margin-bottom: -2em;
  }
  .name{
    margin-bottom: .5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .filename{
    color: #939393;
    font-size: .9em;
    line-height: 1;
  }
  .version {
    color: #939393;
  }
  .filesize {
    white-space: nowrap;
  }
  .btnsWrap{
    @include respond-to("md") {
      text-align: right;
    }
  }
  .btn {
    margin: .3em .3em;
    &.btnChangelog {
      font-size: 1.25em;
      padding-left: 0;
      padding-right: 0;
      min-width: 51px;
    }
  }
}
