@import "../../../assets/styles/mixins/respondTo";

.top {
  padding: 4.3em 3em 3em;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: white;
}
@include respond-to("md") {
  .topLeft {
    padding-right: 2em;
  }
  .topRight {
    padding-left: 3em;
    border-left: 1px solid #EEF2F7;
    height: 100%;
  }
}


.attachmentsWrap {
  margin-top: 2em;
}

.techCharacteristicsTabContent{
  overflow-x: auto;

  table {
    min-width: 100%;
    border: none;
    border-spacing: 0;
    td {
      padding: 1em 1.5em;
      border: none;
      border-bottom: 1px solid #E4EAEF;
    }
  }
}
.descriptionTabContent {
  :global {
    @import "./BackendsHtmlGrid";
  }
}
