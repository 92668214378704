:global(.MuiStepper-root).steps {
  background-color: var(--c-primary);
  border-radius: 9px 9px 9px 0;
  padding-top: 3em;
  padding-bottom: 3em;
  :global {
    .MuiStep-root {
      &:local(.activeStep) {
        &~.MuiStep-root {
          .MuiButtonBase-root {
            .MuiStepLabel-root .MuiStepLabel-iconContainer{
              &:after {
                opacity: 0;
              }
              border-color: #657DA7;
            }
            .MuiStepLabel-label {
              color: #657DA7;
            }
          }
          .MuiStepConnector-alternativeLabel .MuiStepConnector-line {
            background-color: #657DA7;
          }
        }
      }
      .MuiStepLabel-label {
        color: var(--c-primary_contrast);
      }
      .MuiStepConnector-alternativeLabel {
        right: calc(50% + -3px);
        left: calc(-50% + 22px);
        .MuiStepConnector-line {
          background-color: var(--c-secondary);
          height: 3px;
          border: none;
        }
      }
      .MuiButtonBase-root {
        position: relative;
        .MuiStepLabel-root {
          .MuiStepLabel-iconContainer {
            .MuiSvgIcon-root {
              display: none;
            }

            position: relative;
            height: 1em;
            width: 1em;
            border: {
              width: 3px;
              style: solid;
              color: var(--c-secondary);
              radius: 100px;
            }

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 100px;
              background-color: var(--c-secondary);
              height: .5em;
              width: .5em;
            }
          }

          .MuiStepLabel-labelContainer {
            position: absolute;
            left: 0;
            right: 0;
            top: 2em;

            .MuiStepLabel-label {
              margin-top: .8em;
              font-size: 1em;
            }
          }
        }
      }
    }

  }
}
.close {
  color: white;
  background-color: rgba(0,0,0,.2);
  line-height: .7;
}
