.content {

  ul {
    margin: 2em 0;
    padding: 0;
    list-style: none;
    li {
      vertical-align: top;
      position: relative;
      margin-bottom: 1em;
      padding-left: 1.4em;
      &:before {
        content: "";
        position: absolute;
        top: .3em;
        left: 0;
        display: inline-block;
        height: 1em;
        width: 1em;
        margin-right: 10px;
        background: {
          image: url("../../assets/icons/check.svg");
          position: center;
          size: cover;
        };
      }
    }
  }

  blockquote {
    padding: 2em 2.2em;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 30px;
    line-height: 2;

    font-style: italic;
    font-weight: 600;
    color: #414646;
  }

  ol {
    padding-left: 0;
    padding-right: 0;
    margin: 20px 0 30px;
    list-style: none;
    counter-set: li;
    position: relative;
    li {
      counter-increment: li;
      margin: 10px 0 25px 35px;
      &::before {
        position: absolute;
        left: 0;
        display: inline-block;
        height: 25px;
        width: 25px;
        background-color: #2F5AA8;
        color: white;
        text-align: center;
        line-height: 25px;
        content: counter(li);
        margin-right: 8px;
        border-radius: 25px;
      }
    }
  }

  a {
    color: #2F5AA8;
  }

  table {
    border: 0;
    tr {
      td {
        padding: .5em;
        border: {
          width: 0 0 1px;
          style: solid;
          color: #E4EAEF;
        }
      }
    }
  }
  img {
    height: auto;
  }
}
