:root {
  // Basic layout settings
  --bls-bg: #FFFFFF;
  --bls-headings-c: #414646;
  --bls-text-c: #555555;

  /// inverted area
  --bls-i-bg: var(--c-primary);
  --bls-i-headings-c: #FFFFFF;
  --bls-i-text-c: #FFFFFF;

  //--bls-mainContainer-pt: 5em;
  //--bls-mainContainer-pb: 5em;
}
:root {
  --bls-banner-pb: 10em;
  --bls-banner-pt: 5em;
}

:root {
  --bls-section-pt: 5em;
  --bls-section-pb: 5em;
}

.section {
  padding-top: var(--bls-section-pt);
  padding-bottom: var(--bls-section-pb);
}

.sectionAbsoluteBgWhite {
  position: relative;
  background-color: white;

  >* {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: -1000px;
    left: -1000px;
    background-color: white;
  }
}
.sectionOverlayBanner {
  position: relative;
  z-index: 1;
  margin-top: calc(var(--bls-section-pt) * -1 + var(--bls-banner-pb) / 2 * -1);
}
