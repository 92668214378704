.MuiButtonBase-root {
  padding: .9em 2em;
  box-shadow: unset;
  font-size: inherit;

  &.MuiButton-containedSecondary:hover {
    box-shadow: 0 4px 15px rgba(37, 215, 151, 0.5);
  }
}
.MuiButton-contained:not(.MuiButton-containedSecondary){
  &:hover{
    background-color: #333D46;
    color: white;
  }
  &.Mui-disabled {
    background-color: #a3a2a2;
    color: #454444;
    cursor: not-allowed;
  }
}
.MuiIconButton-root {
  padding: .9em;
}
