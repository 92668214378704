@import "./basicLayoutSettings.module";

:root {
  --c-primary: #2E4670;
  --c-primary_contrast: white;
  --c-primary_rgb: 46, 70, 112;

  --c-secondary: #25D797;
  --c-secondary_contrast: white;
  --c-secondary_rgb: 37, 215, 151;
}
