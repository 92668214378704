@import "../../../assets/styles/mixins/flexSpacing";

.links {
  list-style: none;
  padding: 0;
  @include flex-spacing(30px);

  &.vertical{
    @include flex-spacing(15px, 30px);
    flex-direction: column;
    align-items: flex-start;
  }
  .link {
    margin: 0;
    line-height: 1;
    a {}
  }
}