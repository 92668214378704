.block{
  $p-lr: 1.4em;
  $p-b: 1.4em;
  height: 100%;
  padding: $p-lr $p-b;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active{
    .linkWrap .link {
      background-color: var(--c-secondary);
      color: var(--c-secondary_contrast);
    }
    .title {
      color: #2F5AA8;
    }
  }

  &.bordered {
    box-shadow: none;
    border: 1px solid #E3EAF0;
  }

  .imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;
    img {

    }
  }
  .title {
    transition: color .3s;
  }
  .description {
    margin: 0 0 1em;
  }
  .linkWrap {
    width: calc(100% + #{$p-lr} * 2);
    display: block;
    border: {
      width: 1px 0 0;
      style: solid;
      color: #F0F0F0;
    }
    margin: auto -#{$p-lr} -#{$p-b};
    .link {
      display: block;
      padding: 10px 20px;
      text-align: center;
      color: inherit;
      text-decoration: none;
      transition: background-color .3s, color .3s;
      border-radius: 0 0 10px 10px;
    }
  }
}
