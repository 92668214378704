html, html body{
  font: {
    family: 'Inter', sans-serif;
  }
  line-height: 1.7;
  margin: 0;

  color: var(--bls-text-c);

  h1, .like-h1, h2, .like-h2, h3, .like-h3, h4, .like-h4, h5, .like-h5 {
    font-weight: 600;
    color: var(--bls-headings-c);
  }
  h1, .like-h1{
    font-size: 1.7em;
  }
  h2, .like-h2{
    font-size: 1.4em;
  }
  h3, .like-h3{
    font-size: 1.2em;
  }
  h4, .like-h4{
    font-size: 1.1em;
  }
  h5, .like-h5{
    font-size: 1em;
  }
  ::selection {
    background-color: var(--c-primary);
    color: white;
  }

  h1, h2, h3, h4, h5, h6, p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
body {
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  outline-offset: 4px;
  &:focus {
    outline: 1px solid rgba(var(--c-primary_rgb, #000), .2);
  }
}
a {
  color: #2F5AA8;
}
.like-a  {
  color: #2F5AA8;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
img {
  object-fit: cover;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: .5em !important;
}
::-webkit-scrollbar-thumb {
  background: var(--c-primary) !important;
}
video {
  width: 100%;
  background-color: black;
}
