.page {
  color: white;
  background: {
    color: var(--c-primary);
    image: url("../../assets/images/some_fractals.png");
    position: right bottom;
    repeat: no-repeat;
    size: auto;
  };
  height: 100vh;

  padding-top: var(--header-h);
  padding-bottom: var(--header-h);

  display: flex;
  align-items: center;
  h1,h2,h3,h4,h5{
    color: inherit;
  }

  .title {
    margin-bottom: .5em;
  }
  .text {
    display: block;
    line-height: 2;
  }
  .btn {
    margin-top: 2em;
  }
}
