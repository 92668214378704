body {
  @import "./Button";
  @import "./FormControl.common";
  @import "./Input";
  @import "./InputLabel";
  @import "./Select";
  @import "./Tabs";
  @import "./Accordion";
  @import "./Avatar";
  @import "./Chip";
  @import "./Snackbar";


  .MuiTypography-h1,
  .MuiTypography-h2,
  .MuiTypography-h3
  {
    font-weight: 500;
  }
  .MuiTypography-h4,
  .MuiTypography-h5
  {
    font-weight: 600;
  }
}
