@import "../../assets/styles/mixins/respondTo";

.block{
  margin-bottom: 2em;
  $p-lr: 20px;
  $p-b: 20px;
  padding: $p-lr $p-b;
  text-decoration: none;
  color: inherit;
  border: {
    style: solid;
    width: 1px;
    color: #E3EAF0;
    radius: 10px;
  };
  transition: border-color .3s;

  display: flex;
  flex-direction: column;
  @include respond-to("md") {
    flex-direction: row;
  }


  &:hover{
    border-color: var(--c-secondary);
  }

  .imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    border: {
      style: solid;
      width: 1px;
      color: #D3DBE3;
      radius: 10px;
    }
    @include respond-to("md") {
      max-width: 200px;
    }
    img {

    }
  }

  .content {
    margin-top: 1em;
    margin-left: unset;
    @include respond-to("md") {
      margin-top: unset;
      margin-left: 2em;
    }
    .title {

    }
    .description {

    }
  }
}