.MuiInputBase-root:not(.ignoreCustomStyling),
.MuiFormControl-root:not(.ignoreCustomStyling){
  legend {
    visibility: hidden;
    display: none;
  }
  .MuiInputLabel-root:not(.ignoreCustomStyling),
  .MuiInputLabel-root:not(.ignoreCustomStyling).MuiFormLabel-filled{
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
  }
  .PrivateNotchedOutline-root-1 {
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline{
    border: {
      color: #D3DBE3;
      radius: 5px;
    }
  }
}
.MuiInputLabel-root:not(.ignoreCustomStyling){
  margin-bottom: 10px;
  color: #333333;
  font-weight: 600;
}
.PrivateNotchedOutline-root-5 {
  top: 0;
}
