.block {
  padding: calc(var(--header-h) + var(--bls-banner-pt)) 0 var(--bls-banner-pb);
  background-color: var(--c-primary);
  color: #ffffff;
  h1,h2,h3,h4,h5 {
    color: #ffffff;
  }
  border-bottom-right-radius: 50px;
  position: relative;

  background: {
    position: right bottom;
    repeat: no-repeat;
  }
  .title {
    u {
      text-decoration: none;
      box-shadow: inset 0px -12px 0px 0px var(--c-secondary);
    }
    &.titleLarge {
      font-size: 2em;
    }
  }
}
