.block {
  background-color: #333D46;
  color: white;
  h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  padding: 5em 0;

  a {
    display: inline-block;
    margin: 0 15px 0 0;
    color: inherit;
    text-decoration: none;
  }
}
