@import "../../assets/styles/mixins/respondTo";

.modal {
  overflow-y: auto;
}
.block {
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin: 5em auto 3em;
  min-height: 200px;
  max-width: calc(100vw - 30px);
  .close {
    position: absolute;
    z-index: 123;

    right: .3em;
    top: .3em;
    @include respond-to("sm") {
      right: .7em;
      top: .7em;
    }

    line-height: 1;
    font-size: 2em;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color .3s;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 1em;
    width: 1em;

    &:hover {
      background-color: #F3F3F3;
      color: black;
    }
  }
}

.title {
  display: block;
  padding: 1em;
  @include respond-to("sm") {
    padding: 1.5em 2em;
  }
  :global {
    .MuiDivider-root {
      margin-left: -1em;
      margin-right: -1em;
      @include respond-to("sm") {
        margin-left: -2em;
        margin-right: -2em;
      }
    }
  }
}
.content {
  padding: 1em 1em 1em;
  @include respond-to("sm") {
    padding: 2em 2em 1.5em;
  }
  :global {
    .MuiDivider-root {
      margin-left: -1em;
      margin-right: -1em;
      @include respond-to("sm") {
        margin-left: -2em;
        margin-right: -2em;
      }
    }
  }
}
.title + .content {
  padding-top: 1em;
}
