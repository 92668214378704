.block {
  background: #FFFFFF;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 2em 2.5em;
  position: relative;
}

.btn {
  position: relative;
  height: calc(100% - 10px);
}

.searchField{
  :global {
    .MuiInputAdornment-root{
      font-size: 1em;
    }
  }
}
