.mainSlider {
  margin-bottom: 1em;
}
.paginationSlider {
  .slide {
    //max-height: 5em;
    cursor: pointer;
    margin: 0 2px;
    border: {
      width: 2px;
      style: solid;
      radius: 10px;
    };
    overflow: hidden;
    transition: border-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &.image {
     border-color: #DBDBDB;
    }
    &.video {
      height: 70px;
      position: relative;
      background-color: #F5F7F9;
      border-color: #F5F7F9;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: {
          color: #333D46;
          position: center;
          repeat: no-repeat;
          image: url("../../assets/icons/play-arrow.svg");
        }
        border-radius: 50px;
        height: 32px;
        width: 32px;transition: background-color .3s;
      }

      &:hover:before {
        background-color: var(--c-secondary);
      }
    }
  }
  :global(.slick-current) .slide {
    border-color: #00DCAE;
    &.image {
    }
    .video:before {
      background-color: #00DCAE;
    }
  }
  :global(.slick-slide) > div {
    display: block;
    align-items: unset;
    justify-content: unset;
  }
}
.slide.video {
  video {
    max-width: 100%;
  }
}