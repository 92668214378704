@import "../../assets/styles/mixins/respondTo";

.product {
  position: relative;
  padding: 1em 0;
  .imgWrap {
    display: inline-block;
    border: {
      color: #D6D6D6;
      style: solid;
      width: 1px;
      radius: 10px;
    }
    overflow: hidden;
    img {
      max-height: 130px;
    }
  }
  .close {
    padding: unset;
    min-width: unset;
    color: red;
    font-size: 1.5em;

    position: absolute;
    top: 2em;
    right: 0;

    @include respond-to("md") {
      position: unset;
      top: unset;
      right: unset;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
